import React from 'react'
import { Link } from 'gatsby'

import {
  Breadcrumb,
  BreadcrumbItem,
  PageTitle,
  SpecificContactForm,
  TitleBorder,
  VerticalCard,
  VerticalCardBody
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'


const EmploymentOpportunitiesPage = (props) => {
  return (
    <Layout>
      <SEO
        title="Employment Opportunities"
        description="Browse current employment opportunities with BSCS Science Learning and enjoy a flexible and inclusive work environment."
        canonicalUrl="https://bscs.org/connect/employment-opportunities/"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            Connect
          </BreadcrumbItem>
          <BreadcrumbItem>
            Employment Opportunities
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>Join the BSCS team</PageTitle>
        <TitleBorder />
        <div className="flex flex-wrap-reverse">
          <div className="w-full lg:w-3/4">
            {/* <p>We currently do not have any openings.</p> */}

            <p>If you're a science educator or science education researcher interested in working with us, please contact BSCS Careers. BSCS offers a flexible and inclusive work environment, with a strong commitment to the professional growth of all staff. Our office is in Colorado Springs, the #1 city in US News & World Report's 2019 Most Desirable Places to Live. Located along the Front Range of the Rockies one hour south of Denver, Colorado Springs is known for a mild climate, a vibrant arts community, and year-round outdoor recreation.</p>

            {/* <p>BSCS Science Learning is committed to recruitment of a diverse staff. We are an equal opportunity employer. All applicants will receive consideration for employment without regard to age, race, sex, color, religion, national origin, disability (physical and/or mental), sexual orientation, gender identity or expression, veteran status, military obligations, marital status, pregnancy, genetic information, or any status protected by federal, state, or local law.</p> */}
          </div>
          <div className="justify-center lg:w-1/4 w-full">
            <VerticalCard>
              <VerticalCardBody className="flex justify-center">
                <SpecificContactForm person="BSCS Careers" />
              </VerticalCardBody>
            </VerticalCard>
          </div>
        </div>
        <hr />
        <div>
          <p><strong>Position Title: </strong>Science Educator, Instructional Materials Development</p>
          <p><strong>Date: </strong>8/24/20</p>
          <p><strong>Job Announcement: </strong><a href="https://media.bscs.org/bscsmw/employment-opportunities/job_announcement_science_educator_8-24-20.pdf" target="_blank">Please click here for details and how to apply&nbsp;<sup><i class="fas fa-external-link-alt external-link-icon"></i></sup></a></p>
          <p><strong>Description: </strong>BSCS Science Learning (BSCS) has immediate openings for one or more instructional materials developers with expertise in science education to work on a large-scale instructional materials development (IMD) project we have underway: the OpenSciEd Middle School project. The OpenSciEd Middle School project is developing a three-year middle school science curriculum for the NGSS. Information about this project is available at <a href="https://openscied.org" target="_blank" rel="noopener noreferrer">https://openscied.org&nbsp;<sup><i class="fas fa-external-link-alt external-link-icon"></i></sup></a>. This position could be filled with full-time or part-time staff.</p>
        </div>
        <hr />
        <div className="flex flex-wrap">
          <div>
            <p>BSCS Science Learning is committed to recruitment of a diverse staff. We are an equal opportunity employer. All applicants will receive consideration for employment without regard to age, race, sex, color, religion, national origin, disability (physical and/or mental), sexual orientation, gender identity or expression, veteran status, military obligations, marital status, pregnancy, genetic information, or any status protected by federal, state, or local law.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmploymentOpportunitiesPage
